import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "shanti-som"
    }}>{`Shanti-Som`}</h1>
    <h2 {...{
      "id": "project-outline"
    }}>{`Project outline`}</h2>
    <p>{`Shanti-Som is a website for the wellness retreat in spain. The main purpose it to attract more visitors through google and have a visual representation of the retreat. `}</p>
    <h2 {...{
      "id": "architecture"
    }}>{`Architecture`}</h2>
    <p><img alt="Alt text" src={require("./blob/ShantiSom-eind-architectuur.drawio.png")} /></p>
    <blockquote>
      <p parentName="blockquote">{`this overview includes the `}<inlineCode parentName="p">{`test`}</inlineCode>{` setup, during acceptance by the customer.`}</p>
      <h2 parentName="blockquote" {...{
        "id": "coding--techniques"
      }}>{`Coding & techniques`}</h2>
      <ul parentName="blockquote">
        <li parentName="ul">{`Headless CMS in `}<strong parentName="li">{`Wordpress`}</strong></li>
        <li parentName="ul">{`Frontend in `}<strong parentName="li">{`NextJS`}</strong></li>
      </ul>
    </blockquote>
    <h2 {...{
      "id": "environments"
    }}>{`Environments`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://testing.shantisom.com"
        }}>{`https://testing.shantisom.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cms.shantisom.com/wp-admin"
        }}>{`https://cms.shantisom.com/wp-admin`}</a></li>
    </ul>
    <h2 {...{
      "id": "repositories"
    }}>{`Repositories`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Repository`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HybrIT Wordpress plugin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom plugin made to expose extra data to the frontend`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-Wordpress"
            }}>{`https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-Wordpress`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Frontend NextJS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The frontend that parses and presents the data from wordpress`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-NextJS"
            }}>{`https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-NextJS`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "getting-started-with-development"
    }}>{`Getting started with development`}</h2>
    <h3 {...{
      "id": "frontend"
    }}>{`Frontend`}</h3>
    <ol>
      <li parentName="ol">{`Checkout `}<a parentName="li" {...{
          "href": "https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-NextJS"
        }}>{`https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-NextJS`}</a></li>
      <li parentName="ol">{`Run yarn`}</li>
      <li parentName="ol">{`Run yarn dev`}</li>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "http://localhost:3000"
        }}>{`http://localhost:3000`}</a></li>
    </ol>
    <h3 {...{
      "id": "wordpress"
    }}>{`Wordpress`}</h3>
    <ol>
      <li parentName="ol">{`Login at `}<a parentName="li" {...{
          "href": "https://cms.shantisom.com/wp-admin"
        }}>{`https://cms.shantisom.com/wp-admin`}</a></li>
      <li parentName="ol">{`Once in you can change anything you like`}</li>
    </ol>
    <h3 {...{
      "id": "wordpress-plugin"
    }}>{`Wordpress plugin`}</h3>
    <ol>
      <li parentName="ol">{`Checkout `}<a parentName="li" {...{
          "href": "https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-Wordpress"
        }}>{`https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-Wordpress`}</a></li>
      <li parentName="ol">{`At this point you have to manually upload the plugin to update and use it`}</li>
    </ol>
    <h2 {...{
      "id": "common-errors"
    }}>{`Common errors`}</h2>
    <p>{`If Shantisom pipeline doesn't get automatically triggered by changes, make sure that the PAT is still valid (not expired).`}</p>
    <ol>
      <li parentName="ol">{`in Azure DevOps the Personal Access Token (Azure PAT) can be found in the Wordpress CMS (`}<a parentName="li" {...{
          "href": "https://cms.shantisom.com/wp-admin/admin.php?page=hybrit-settings"
        }}>{`https://cms.shantisom.com/wp-admin/admin.php?page=hybrit-settings`}</a>{`).
`}<img alt="wordpress-reset-pat" src={require("./blob/wordpress-pat-aanpassen.png")} /></li>
      <li parentName="ol">{`Login with user code@hybrit.org at `}<a parentName="li" {...{
          "href": "https://dev.azure.com/hybritdev"
        }}>{`https://dev.azure.com/hybritdev`}</a>{` `}</li>
    </ol>
    <p><img alt="img.png" src={require("./img.png")} /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Go to Personal access tokens (click top right, use icon with a cogwheel)`}</li>
    </ol>
    <p><img alt="img_1.png" src={require("./img_1.png")} />{`
4. Click on "+ New Token"`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Fill in form like picture shown below`}</li>
    </ol>
    <p><img alt="img_2.png" src={require("./img_2.png")} /></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Make sure to immediately save the code to LastPass, and update the Azure PAT to the plugin on `}<a parentName="li" {...{
          "href": "https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-Wordpress"
        }}>{`https://dev.azure.com/hybritdev/Shantisom/_git/Shantisom-Wordpress`}</a>{` `}</li>
    </ol>
    <p><img alt="img_3.png" src={require("./img_3.png")} /></p>
    <h3 {...{
      "id": "publishing-failed-you-are-probably-offline"
    }}>{`Publishing failed. You are probably offline.`}</h3>
    <p>{`Make sure you use the classic editor, NOT the block editor.`}</p>
    <h3 {...{
      "id": "wordpress-hosting"
    }}>{`Wordpress hosting`}</h3>
    <p>{`The CMS is hosted by jucra. If support is needed (i.e. curl no longer available) see `}<a parentName="p" {...{
        "href": "blob/Support-Ticket-Best-Practices.pdf"
      }}>{`Jucra support guide`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      